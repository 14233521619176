import React, { useRef, useState } from "react";
import { Container } from "@rentivo/gatsby-core";
import PropertyLayout from "@rentivo/gatsby-theme-barcelona/src/components/layouts/PropertyLayout";
import loadable from "@loadable/component";
import PropertyDescription from "@rentivo/gatsby-core/src/components/property/PropertyDescription";
import PropertyFeatures from "@rentivo/gatsby-core/src/components/property/PropertyFeatures";
import PropertyRates from "@rentivo/gatsby-core/src/components/property/PropertyRates";
import PropertyRooms from "@rentivo/gatsby-core/src/components/property/PropertyRooms";
import {
  BATHROOM,
  BEDROOM,
} from "@rentivo/gatsby-core/src/constants/lycanConstants";
import PropertyIncluded from "@rentivo/gatsby-core/src/components/property/PropertyIncluded";
import InView from "@rentivo/gatsby-core/src/components/generic/InView";
import PropertyPolicies from "@rentivo/gatsby-core/src/components/property/PropertyPolicies";
import PropertyHead from "@rentivo/gatsby-core/src/components/property/PropertyHead";
import BlockImageGallery from "@rentivo/gatsby-core/src/components/property/BlockImageGallery";
import PropertyHeader from "@rentivo/gatsby-core/src/components/property/PropertyHeader";
import { chakra } from "@chakra-ui/react";
import PropertyFAQs from "@rentivo/gatsby-core/src/components/property/PropertyFAQs";
import {CENTRAL_LOGO} from "@rentivo/gatsby-core/src/components/ui/HeaderNavbar";

const PropertyMap = loadable(() =>
  import("@rentivo/gatsby-core/src/components/property/PropertyMap")
);
const PropertyAvailability = loadable(() =>
  import("@rentivo/gatsby-core/src/components/pricing/AvailabilityPicker")
);

const PropertyBlockWrapper = ({
  children,
  styleProps = {},
  className = "",
}) => (
  <chakra.div
    mb={6}
    boxShadow={{ base: "none", lg: "md" }}
    borderRadius="md"
    bg={{ base: "transparent", lg: "white" }}
    p={{ base: 0, lg: 6 }}
    className={className}
    {...styleProps}
  >
    {children}
  </chakra.div>
);

const AvailabilityBlockWrapper = ({ children, className = "" }) => (
  <PropertyBlockWrapper
    className={className}
    styleProps={{
      px: "5px",
    }}
  >
    {children}
  </PropertyBlockWrapper>
);

export const PropertyLayoutComponent = ({
  footer,
  updateBar,
  topBar,
  menuItems,
}) => {
  const layoutRef = useRef(null);
  const [container, setContainer] = useState(null);
  return (
    <PropertyLayout
      menuItems={menuItems}
      updateBar={updateBar}
      topBar={topBar}
      ref={layoutRef}
      footer={footer}
      publicNav={true}
      overrideLayout={CENTRAL_LOGO}
    >
      <PropertyHead />
      <BlockImageGallery styleProps={{ mb: 6 }} />
      <div ref={setContainer}>
        <Container>
            <PropertyHeader styleProps={{ mb: 6 }} />
            <PropertyDescription wrapper={PropertyBlockWrapper} />
            <PropertyRooms type={BEDROOM} wrapper={PropertyBlockWrapper} />
            <PropertyRooms type={BATHROOM} wrapper={PropertyBlockWrapper} />
            <PropertyIncluded wrapper={PropertyBlockWrapper} />
            <PropertyFeatures wrapper={PropertyBlockWrapper} />
            <PropertyFAQs wrapper={PropertyBlockWrapper} />
            <InView>
              <PropertyMap wrapper={PropertyBlockWrapper} />
              <PropertyAvailability wrapper={AvailabilityBlockWrapper} />
            </InView>
            <PropertyRates wrapper={PropertyBlockWrapper} />
            <PropertyPolicies wrapper={PropertyBlockWrapper} />
        </Container>
      </div>
    </PropertyLayout>
  );
};

export default PropertyLayoutComponent;
